import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

import LandingContentArea from '../templates/landing-content-area'

export default function ProductPage({ data, pageContext }) {
  const layoutPieces = data.contentfulPage.pageComponents
  return (
    <Layout header={data?.contentfulPage?.header} footer={data?.contentfulPage?.footer}>
      <LandingContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <title>{data?.contentfulPage?.metaTitle}</title>
    <meta name="description" content={data?.contentfulPage?.metaDescription} />
    <meta property="og:title" content={data?.contentfulPage?.ogMetaTitle ? data.contentfulPage.ogMetaTitle : data?.contentfulPage?.metaTitle} />
    <meta property="og:description" content={data?.contentfulPage?.ogMetaDescription ? data.contentfulPage.ogMetaDescription : data?.contentfulPage?.metaDescription} />
    <meta property="og:type" content={data?.contentfulPage?.ogMetaType} />
    <meta property="og:url" content="https://www.entrata.com" />
    <meta property="og:image" content={data?.contentfulPage?.ogMetaImage?.image ? data?.contentfulPage?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query HomePageContent {
    contentfulPage {
      header
      footer
      metaDescription
      metaTitle
      ogMetaTitle
      ogMetaDescription
      ogMetaType
      ogMetaImage {
        image {
          url
        }
      }
      # Get the list of components to load into the page
      pageComponents {
        ... on Node {
          internal {
            type
          }
        }
        ... on ContentfulLandingBanner {
          ...H1Banner
        }
        ... on ContentfulPortfolioCarousel {
          ...ClientFeature
        }
        ... on ContentfulSuiteCardsComponent {
          ...H1SuiteCards
        }
        ... on ContentfulDuplexFeature {
          ...H1Duplex
        }
        ... on ContentfulAttributeListComponent {
          ...AttributeListComponent
        }
        ... on ContentfulJoinTheTeamFeature {
          ...JoinTheTeamFeature
        }
        ... on ContentfulNewsFeature {
          ...NewsFeature
        }
        ... on ContentfulFeaturedResourceComponent {
          ...FeaturedResourceComponent
        }
        ... on ContentfulMiniCaseStudySlider {
          ...CaseStudySlider
        }
        ... on ContentfulLogoDisplay {
          ...H1LogoDisplay
        }
        ... on ContentfulBenefitGraph {
          ...H1UnifiedPlatform
        }
        ... on ContentfulInfoGrid {
          ...H1InfoGrid
        }
        ... on ContentfulEntrataStatComponent {
          ...H1EntrataStats
        }
        ... on ContentfulDuplexBladeStack {
          ...H1BladeStack
        }
        ... on ContentfulRatingsGrid {
          ...H1RatingsGrid
        }
        ... on ContentfulOneByThreeImageGrid {
          ...H1Resources
        }
        ... on ContentfulFormComponent {
          ...H1FormComponent
        }
        ... on ContentfulVideoFeature {
          ...H1VideoFocus
        }
        ... on ContentfulFormattedQuote {
          ...H1Quote
        }
        ... on ContentfulEventCta {
          ...H1Q2SummitBlade
        }
      }
    }
  }
`

/*

---------- Contentful Blog Feature Query ----------

... on ContentfulBlogFeature {
  ...BlogFeature
}

---------- Contentful Quote Duplex Query ----------

... on ContentfulQuoteDuplex {
  ...QuoteDuplex
}

*/