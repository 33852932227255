import React from 'react'

export function symbolResize(text) {
    if (text === null || text === undefined) return null
    const arr = text.split('')
    const newArr = []
    arr.map((char, index) => {
      if (char === '™' || char === '®' || char === '©') {
        newArr.push(<sup key={index}>{char}</sup>)
      } else if (char === '\\') {
        newArr.push(<br key={index}/>)
      } else {
        newArr.push(char)
      }
    })
    return newArr
}

export function checkSvg(url) {
  let fileType = ''
  for (let i = url.length - 3; i < url.length; i++) {
    fileType += url[i]
  }
  if (fileType === 'svg') {
    return true
  } else {
    return false
  }
}