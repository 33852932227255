import React, { useEffect } from 'react'

export default function WistiaThumbnail (props) {
    useEffect(() => {
        const script1 = document.createElement("script")
        const script2 = document.createElement("script")

        script1.src = "https://fast.wistia.com/embed/medias/videolink.jsonp"
        script1.defer = true;

        script2.src = "https://fast.wistia.com/assets/external/E-v1.js"
        script2.defer = true;

        document.body.appendChild(script1)
        document.body.appendChild(script2)
    }, [])

    return (
        <>
            <div className="wistia_responsive_padding" style={{padding: '56.25% 0 0 0', position: 'relative'}}><div className="wistia_responsive_wrapper" style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}><span className={`wistia_embed wistia_async_${props.videoId} popover=true popoverAnimateThumbnail=false videoFoam=true`} style={{display: 'inline-block', height: '100%', position: 'relative', width: '100%'}}>&nbsp;</span></div></div>
        </>
    )
}
