import * as React from "react"
import { Suspense } from "react"
//import component list
//import LandingBanner from '../components/landing-banner'
import ClientFeatureSlider from '../components/client-feature'
import LoadingSpinner from '../components/_loading-spinner'

//const FourBucket = React.lazy(() => import('../components/four-bucket'))
//const GlobalDuplexBanner = React.lazy(() => import('../components/global-duplex-banner'))
const FeatureList = React.lazy(() => import('../components/feature-list'))
const BlogFeature = React.lazy(() => import('../components/blog-feature'))
const QuoteFeature = React.lazy(() => import('../components/quote-feature'))
const ContainedBanner = React.lazy(() => import('../components/contained-banner'))
const NewsFeature = React.lazy(() => import('../components/news-feature'))
const FeaturedResource = React.lazy(() => import('../components/resource-feature'))
const MiniCaseStudySlider = React.lazy(() => import('../components/mini-case-study/mini-case-study-slider'))
const H1Banner = React.lazy(() => import('../components/h1-homepage-2024/h1-banner'))
const H1LogoDisplay = React.lazy(() => import('../components/h1-homepage-2024/h1-q2-logo-display'))
const H1FourBucket = React.lazy(() => import('../components/h1-homepage-2024/h1-four-bucket'))
const H1InfoGrid = React.lazy(() => import('../components/h1-homepage-2024/h1-q2-info-grid'))
const H1EntrataStats = React.lazy(() => import('../components/h1-homepage-2024/h1-entrata-stats'))
const H1BladeStack = React.lazy(() => import('../components/h1-homepage-2024/h1-blade-stack'))
const H1Resources = React.lazy(() => import('../components/h1-homepage-2024/h1-resources'))
const H1RatingsGrid = React.lazy(() => import('../components/h1-homepage-2024/h1-ratings-grid'))
const H1FormComponent = React.lazy(() => import('../components/h1-homepage-2024/h1-forms-component'))
const H1VideoFocus = React.lazy(() => import('../components/h1-homepage-2024/h1-video-focus'))
const H1Quote = React.lazy(() => import('../components/h1-homepage-2024/h1-quote-component'))
const H1Duplex = React.lazy(() => import('../components/h1-homepage-2024/h1-duplex'))
const H1Q2Banner = React.lazy(() => import('../components/h1-homepage-2024/h1-q2-banner'))
const H1Q2SummitBlade = React.lazy(() => import('../components/h1-homepage-2024/h1-q2-summit-blade'))

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces?.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulLandingBanner' && <H1Q2Banner layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulLogoDisplay' && <Suspense key={index} fallback={<LoadingSpinner />}><H1LogoDisplay layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulInfoGrid' && <Suspense key={index} fallback={<LoadingSpinner />}><H1InfoGrid layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulEventCta' && <Suspense key={index} fallback={<LoadingSpinner />}><H1Q2SummitBlade layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulEntrataStatComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><H1EntrataStats layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulDuplexBladeStack' && <Suspense key={index} fallback={<LoadingSpinner />}><H1BladeStack layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulOneByThreeImageGrid' && <Suspense key={index} fallback={<LoadingSpinner />}><H1Resources layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulRatingsGrid' && <Suspense key={index} fallback={<LoadingSpinner />}><H1RatingsGrid layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulPortfolioCarousel' && <ClientFeatureSlider layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulSuiteCardsComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><H1FourBucket layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><H1Duplex layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulAttributeListComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FeatureList layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulBlogFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><BlogFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulQuoteDuplex' && <Suspense key={index} fallback={<LoadingSpinner />}><QuoteFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulJoinTheTeamFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><ContainedBanner layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulNewsFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><NewsFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFeaturedResourceComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><FeaturedResource layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulMiniCaseStudySlider' && <Suspense key={index} fallback={<LoadingSpinner />}><MiniCaseStudySlider layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFormComponent' && <Suspense key={index} fallback={<LoadingSpinner />}><H1FormComponent layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulVideoFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><H1VideoFocus layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulFormattedQuote' && <Suspense key={index} fallback={<LoadingSpinner />}><H1Quote layoutPiece={layoutPiece} /></Suspense>,
        ])
      })
    }
}

export default ContentArea
