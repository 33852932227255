import React from 'react'
import '../styles/loading-spinner.css'
import SpinnerIcon from '../assets/loading.svg'

export default function LoadingSpinner() {
  return (
    <div className="loading-spinner-section">
      <div className="loading-spinner-holder">
        <div className="loading-spinner-actual"></div>
        <div className="loading-spinner-icon">
          <SpinnerIcon alt="Loading"/>
        </div>
      </div>
    </div>
  );
}
